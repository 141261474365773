import React from "react"
import { graphql, Link, PageProps } from "gatsby"
import Page from "../components/common/page"
import Header from "../components/header"
import Footer from "../components/footer"
import { useTranslation } from "react-i18next"
const TermsOfUsePage = (props: PageProps) => {
  const { t } = useTranslation()
  return (
    <Page title="Terms of Use">
      <Header />
      <div className="lg:flex lg:flex-row justify-center">
        <div className="w-full lg:w-1/2 px-5 lg:px-1">
          <p className="text-secondary text-2xl lg:text-4xl leading-10 mt-4">
            <strong>{t("tos_title")}</strong>
          </p>
          <p className="my-3 text-left">
            <strong>{t("tos_subtitle")}</strong>
          </p>
          <p className="my-3 text-left">
            {t("tos_text_1")}{" "}
            <a className="text-secondary" href="https://skills4gigs.com/">
              skills4gigs.com
            </a>{" "}
            {t("tos_text_2")}
          </p>
          <p className="my-3 text-left">{t("tos_text_3")}</p>
          <p className="my-3 text-left">{t("tos_text_4")}</p>
          <p className="my-3 text-left text-secondary">
            <strong>{t("tos_header_1")}</strong>
          </p>
          <p className="text-justify">{t("tos_header_1_text")}</p>
          <p className="my-3 text-left text-secondary">
            <strong>{t("tos_header_2")}</strong>
          </p>
          <p className="text-justify">{t("tos_header_2_text")}</p>
          <p className="my-3 text-left text-secondary">
            <strong>{t("tos_header_3")}</strong>
          </p>
          <p className="text-justify">{t("tos_header_3_text")}</p>
          <p className="my-3 text-left text-secondary">
            <strong>{t("tos_header_4")}</strong>
          </p>
          <p className="text-justify">{t("tos_header_4_text")}</p>


          <p className="my-3 text-left text-secondary">
            <strong>{t("tos_header_5")}</strong>
          </p>
          <p className="text-justify">
            {t("tos_header_5_text")}
          </p>
          <p className="my-3 text-left text-secondary">
            <strong>{t("tos_header_6")}</strong>
          </p>
          <p className="text-justify">
            {t("tos_header_6_text")}
            <Link
              className="py-6 font-bold text-secondary"
              to="/data-protection-notice"
            >
              {t("data_protection")}
            </Link>
          </p>
          <p className="my-3 text-left text-secondary">
            <strong>{t("tos_header_7")}</strong>
          </p>
          <p className="text-justify">
            {t("tos_header_7_text")}
            <Link className="py-6 font-bold text-secondary" to="/cookies">
              Cookies
            </Link>
            .
          </p>
          <p className="my-3 text-left text-secondary">
            <strong>{t("tos_header_8")}</strong>
          </p>
          <p className="text-justify">
            {t("tos_header_8_text")}
          </p>
          <p className="my-3 text-left text-secondary">
          <strong>{t("tos_header_9")}</strong>
          </p>
          <p className="text-justify">
          {t("tos_header_9_text")}

          </p>
        </div>
      </div>
      <Footer />
    </Page>
  )
}

export default TermsOfUsePage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["tos", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
